.jdfUploader {
  & :global(.e-btn) {
    border: none !important; 
    border-radius: 3px;
    padding: 0 17px !important;
    font-size: 12px !important;
    height: 34px !important;
    line-height: 34px !important;
    background-color: #EEE !important;
    color: #333 !important;
    text-shadow: none !important;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    letter-spacing: inherit !important;
    margin: 5px !important;
    transition: all 0.2s linear !important;
  }
  
  & :global(.e-btn:hover) {
    background: #444 !important;
    color: #fff !important;
  }
}