$border-color: #b6bfc7;

.lineTable {
  border-left: solid 1px $border-color;
  border-radius: 0.25em;
  
  thead th {
    text-align: center;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  tr:last-of-type {
    border-bottom: 1px solid $border-color;
  }

  td {
    padding: 0 2px;
    text-align: center;
    border-right: 1px solid $border-color;
    vertical-align: middle;
  }
}