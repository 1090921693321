.connection-calendar {
  display: flex;
  justify-content: center;
  
  .connection-calendar-column {
    max-width: min-content;
  }
  
  .table {
    max-width: min-content;
  }
  
  .button-group.fixed-codes-toggles {
    margin-bottom: 1rem;
    
    button {      
      i:before {
        font-size: 1.4em;
        line-height: 1.6em;
        font-weight: 700;
        color: #757575;
      }
    }
    
    button:hover {
      i:before {
        color: white;
      }
    }
  }
  
  @mixin toggle-button {
    @extend .button;
    @extend .button-mini !optional;
    @extend .button-rounded !optional;

    width: 100%;
    text-align: center;
    margin: 0;
    line-height: 2.1em;
    font-size: 0.8em;
  }
  
  .toggle-button {
    @include toggle-button;
  }
  .toggle-button:not(.active) {
    @extend .button-light !optional;
  }
  .toggle-button.weekend-holiday {
    border: 2px solid rgba(0,0,0,0.6);
  }
  
  .toggle-button-group {
    @include toggle-button;
    @extend .button-light !optional;
    @extend .button-border !optional;
    color: #757575;
    text-transform: none;
  }
}