.accordionHeader {
  background-color: #eeeeee;
  border-radius: .25em !important;
  border: none
}

.accordionBody {
  background-color: #f9f9f9;
  border-radius: .25em !important;
}

.cursorPointer {
  cursor: pointer;
}