.notifications-stack {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  -moz-box-shadow: 0 0 64px 15px #ccc;
  -webkit-box-shadow: 0 0 64px 15px #ccc;
  box-shadow: 0 0 64px 15px #ccc;
  background: #cccccc80;
}

.ntf-progress {
  height: 3px;
  width: 100%;
  display: flex;
  background: #d5d9dc;
  border-radius: 4px;
  animation: ntf-progress;
}

@keyframes ntf-progress {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.border-ntf {
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 3px solid !important;
  border-color: #95a5a5;
}

.border-ntf-ok {
  border-color: #1abc9c !important;
}

.border-ntf-fail {
  border-color: #eb4d4b !important;
}

.border-ntf-warning {
  border-color: #fdcb6e !important;
}

