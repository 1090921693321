// additional button type
.button.button-xs {
  padding: 0 7px;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
}

// button groups
.button-group,
.button-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.button-group > .button,
.button-group-vertical > .button {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.button-group > .button:hover,
.button-group-vertical > .button:hover {
  z-index: 1;
}

.button-group > .button:focus, .button-group > .button:active, .button-group > .button.active,
.button-group-vertical > .button:focus,
.button-group-vertical > .button:active,
.button-group-vertical > .button.active {
  z-index: 1;
}

.button-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.button-toolbar .input-group {
  width: auto;
}

.button-group > .button:not(:first-child),
.button-group > .button-group:not(:first-child) {
  margin-left: -1px;
}

.button-group > .button:not(:last-child):not(.dropdown-toggle),
.button-group > .button-group:not(:last-child) > .button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.button-group > .button:not(:first-child),
.button-group > .button-group:not(:first-child) > .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// button vertical alignment fix
.button:not(.button-3d) i {
  top: 0 !important;
}

// active buttons
.button.active {
  opacity: 0.85;
}

.button[disabled], .button[disabled]:hover, .button-disabled, .button-disabled:hover {
  background: #95a5a6 !important;
  color: #ecf0f1 !important;
  cursor: initial !important;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c3135 !important;
  border-color: #2c3135;
}

// additional button backgrounds
.button-transparent-dark {
  background-color: transparent;
  border-color: transparent;
  color: rgba(31, 45, 65, 0.5) !important;
  text-shadow: none;
}
.button-transparent-dark:hover {
  background-color: rgba(31, 45, 65, 0.1);
  border-color: transparent;
  text-shadow: none;
}

.button-gray {
  color: #333333;
  background-color: #dfdfdf;
  border-color: transparent;
  text-shadow: none;
}
.button-gray:hover {
  background-color: #444444;
  color: #ffffff;
}