
.cpEditStatusApproved, .cpEditStatusProcessed {
  background-color: #e5f6fd; // alert-info bg color, blue

  &:hover {
    background-color: #b8e6fa !important; // +10% darker then #e5f6fd
  }
}

.cpEditStatusDisapproved {
  background-color: #fff4e5; // alert-warning bg color, orange

  &:hover {
    background-color: #ffe0b5 !important; // +10% darker then #fff4e5
  }
}

.cpEditStatusForwarded {
  background-color: #edf7ed; // alert-success bg color, green

  &:hover {
    background-color: #cbe8cb !important; // +10% darker then #edf7ed
  }
}

.cpEditStatusError {
  background-color: #fdeded; // alert-error bg color, red

  &:hover {
    background-color: #f8c1c1 !important; // +10% darker then #fdeded
  }
}

// removes green border around cell after action button is clicked
.outlineNone {
  &:focus-within {
    outline: none !important;
  }
}
