.ntfProgress {
  height: 3px;
  width: 100%;
  display: flex;
  background: #d5d9dc;
  border-radius: 4px;
  animation: ntfProgressKeyframes;
}

@keyframes ntfProgressKeyframes {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.notification {
  border-radius: .25rem;
  
  .header {
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  .body {
    background: #f4f6f9;
    max-height: 30vh;
    overflow-y: auto;
  }
}

.borderNtf {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  border-left: 3px solid !important;
  border-color: #95a5a5;
}

.borderNtfOk {
  border-color: #1abc9c !important;
}

.borderNtfFail {
  border-color: #eb4d4b !important;
}

.borderNtfWarning {
  border-color: #fdcb6e !important;
}