@font-face {
  font-family: "Timetable";
  font-style: normal;
  font-weight: normal;
  font-display: block;
  src: url("../fonts/TT.woff2") format('woff2'),
    url('../fonts/TT.woff') format('woff'),
    url('../fonts/TT.ttf') format('truetype');
}

.tti {
  font-family: 'Timetable', sans-serif !important;
  font-style: normal;
  font-weight: normal;
}

.tti.tt-ceske-drahy:before {
  content: '\f021';
}

.tti.tt-chaps:before {
  content: '\f022';
}

.tti.tt-m-logo:before {
  content: '\f023';
}

.tti.tt-dollar:before {
  content: '\f024';
}

.tti.tt-refresh:before {
  content: '\f025';
}

.tti.tt-csad:before {
  content: '\f026';
}

.tti.tt-zsr:before {
  content: '\f028';
}

.tti.tt-r-logo:before {
  content: '\f029';
}

.tti.tt-fill-square:before {
  content: '\f02a';
}

.tti.tt-cross:before {
  content: '\f02b';
}

.tti.tt-triangle-left:before {
  content: '\f02c';
}

.tti.tt-triangle-right:before {
  content: '\f02d';
}

.tti.tt-broken-arrow:before {
  content: '\f02e';
}

.tti.tt-clock:before {
  content: '\f02f';
}

.tti.tt-0:before {
  content: '\f030';
}

.tti.tt-1:before {
  content: '\f031';
}

.tti.tt-2:before {
  content: '\f032';
}

.tti.tt-3:before {
  content: '\f033';
}

.tti.tt-4:before {
  content: '\f034';
}

.tti.tt-5:before {
  content: '\f035';
}

.tti.tt-6:before {
  content: '\f036';
}

.tti.tt-7:before {
  content: '\f037';
}

.tti.tt-8:before {
  content: '\f038';
}

.tti.tt-9:before {
  content: '\f039';
}

.tti.tt-arrow-up:before {
  content: '\f03a';
}

.tti.tt-arrow-down:before {
  content: '\f03b';
}

.tti.tt-circle-fill-half:before {
  content: '\f03c';
}

.tti.tt-circle-fill:before {
  content: '\f03e';
}

.tti.tt-arrow-left:before {
  content: '\f03f';
}

.tti.tt-arrow-right:before {
  content: '\f040';
}

.tti.tt-triangle-empty:before {
  content: '\f041';
}

.tti.tt-triangle-fill:before {
  content: '\f042';
}

.tti.tt-c-logo:before {
  content: '\f043';
}

.tti.tt-x:before {
  content: '\f044';
}

.tti.tt-e-logo:before {
  content: '\f045';
}

.tti.tt-wheelchair-and-person:before {
  content: '\f046';
}

.tti.tt-blind-person:before {
  content: '\f047';
}

.tti.tt-wheelchair:before {
  content: '\f048';
}

.tti.tt-i-logo:before {
  content: '\f049';
}

.tti.tt-pipe:before {
  content: '\f04a';
}

.tti.tt-broken-pipe:before {
  content: '\f04b';
}

.tti.tt-bike:before {
  content: '\f04c';
}

.tti.tt-cutlery:before {
  content: '\f04d';
}

.tti.tt-n-logo:before {
  content: '\f04e';
}

.tti.tt-rectangle-crossed:before {
  content: '\f04f';
}

.tti.tt-paragraph:before {
  content: '\f050';
}

.tti.tt-goblet:before {
  content: '\f051';
}

.tti.tt-r:before {
  content: '\f052';
}

.tti.tt-s-logo:before {
  content: '\f053';
}

.tti.tt-arrow-out-circle:before {
  content: '\f054';
}

.tti.tt-arrow-in-circle:before {
  content: '\f055';
}

.tti.tt-bed-full:before {
  content: '\f056';
}

.tti.tt-bed-empty:before {
  content: '\f057';
}

.tti.tt-x-hammers:before {
  content: '\f058';
}

.tti.tt-phone-in-circle:before {
  content: '\f059';
}

.tti.tt-phone:before {
  content: '\f05a';
}

.tti.tt-car-on-train:before {
  content: '\f05b';
}

.tti.tt-rhomboid-empty:before {
  content: '\f05c';
}

.tti.tt-rhomboid-fill-half:before {
  content: '\f05d';
}

.tti.tt-rhomboid-fill:before {
  content: '\f05e';
}

.tti.tt-rhomboid-crossed:before {
  content: '\f05f';
}

.tti.tt-rect-0:before {
  content: '\f061';
}

.tti.tt-rect-1:before {
  content: '\f062';
}

.tti.tt-rect-2:before {
  content: '\f063';
}

.tti.tt-rect-3:before {
  content: '\f064';
}

.tti.tt-rect-4:before {
  content: '\f065';
}

.tti.tt-rect-5:before {
  content: '\f066';
}

.tti.tt-rect-6:before {
  content: '\f067';
}

.tti.tt-rect-7:before {
  content: '\f068';
}

.tti.tt-rect-8:before {
  content: '\f069';
}

.tti.tt-rect-9:before {
  content: '\f06a';
}

.tti.tt-k-in-circle:before {
  content: '\f06b';
}

.tti.tt-circle-left-half:before {
  content: '\f06c';
}

.tti.tt-circle-right-half:before {
  content: '\f06d';
}

.tti.tt-plane:before {
  content: '\f06e';
}

.tti.tt-window:before {
  content: '\f06f';
}

.tti.tt-case:before {
  content: '\f070';
}

.tti.tt-case-crossed:before {
  content: '\f071';
}

.tti.tt-r-in-square:before {
  content: '\f072';
}

.tti.tt-s-in-circle:before {
  content: '\f073';
}

.tti.tt-t-upside-down:before {
  content: '\f074';
}

.tti.tt-circle-empty:before {
  content: '\f075';
}

.tti.tt-circle-with-dot:before {
  content: '\f076';
}

.tti.tt-wagon:before {
  content: '\f077';
}

.tti.tt-locomotive:before {
  content: '\f078';
}

.tti.tt-cutlery-in-square:before {
  content: '\f079';
}

.tti.tt-bus:before {
  content: '\f07a';
}

.tti.tt-square-fill:before {
  content: '\f07b';
}

.tti.tt-square-empty:before {
  content: '\f07c';
}

.tti.tt-info-in-square:before {
  content: '\f07d';
}

.tti.tt-p-plus-r:before {
  content: '\f07e';
}

.tti.tt-circle-fill-small:before {
  content: '\f0a4';
}

.tti.tt-a5:before {
  content: '\f0a5';
}

.tti.tt-a6:before {
  content: '\f0a6';
}

.tti.tt-a7:before {
  content: '\f0a7';
}

.tti.tt-a8:before {
  content: '\f0a8';
}

.tti.tt-a9:before {
  content: '\f0a9';
}

.tti.tt-aa:before {
  content: '\f0aa';
}

.tti.tt-ab:before {
  content: '\f0ab';
}

.tti.tt-ac:before {
  content: '\f0ac';
}

.tti.tt-ad:before {
  content: '\f0ad';
}

.tti.tt-ae:before {
  content: '\f0ae';
}

.tti.tt-af:before {
  content: '\f0af';
}

.tti.tt-b0:before {
  content: '\f0b0';
}

.tti.tt-b1:before {
  content: '\f0b1';
}

.tti.tt-b2:before {
  content: '\f0b2';
}

.tti.tt-b3:before {
  content: '\f0b3';
}

.tti.tt-ship:before {
  content: '\f0b4';
}

.tti.tt-b5:before {
  content: '\f0b5';
}

.tti.tt-b6:before {
  content: '\f0b6';
}

.tti.tt-b7:before {
  content: '\f0b7';
}

.tti.tt-b8:before {
  content: '\f0b8';
}

.tti.tt-exclamation-mark-triangle:before {
  content: '\f0b9';
}

.tti.tt-exclamation-mark-circle:before {
  content: '\f0ba';
}


.tti.tt-psi:before {
  content: '\f0bb';
}

.tti.tt-f6:before {
   content: '\f0f6';
}

.tti.tt-f7:before {
  content: '\f0f7';
}

.tti.tt-f8:before {
  content: '\f0f8';
}

.tti.tt-f9:before {
  content: '\f0f9';
}

.tti.tt-fa:before {
  content: '\f0fa';
}

.tti.tt-fb:before {
  content: '\f0fb';
}

.tti.tt-fc:before {
  content: '\f0fc';
}

.tti.tt-fd:before {
  content: '\f0fd';
}
