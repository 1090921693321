.overlay-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.87);
  justify-content: center;
  align-items: start;
  padding: 10em 0;

  display: none;
  animation: 0.25s fadeOut;
  animation-fill-mode: forwards;

  visibility: hidden;
}

.overlay-preloader:before {
  content: "\f110";
  font-family: "FontAwesome";
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
  font-size: 2.5em;
}

.overlay-preloader.small:before {
  font-size: 1em !important;
}

.overlay-preloader.centered:before {
  align-self: center;
}

.overlay-preloader.show {
  display: flex;
  animation: 0.25s fadeIn;
  animation-fill-mode: forwards;

  
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}