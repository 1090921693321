.autocomplete-items {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 0.1em 0.6em;
  font-size: 0.9em;
}