.checkbox {
  width: 32px;
  height: 16px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  transition: background-color ease 0.4s;
  display: flex;
  cursor: pointer;

  &.checked {
    background-color: #1ABC9C;
  }

  &:not(.checked) {
    background-color: #F1F1F1;
  }

  $parent: &;

  @at-root {
    .circle {
      margin-left: 0;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: #ffffff;
      transition: left ease 0.4s;
      border: 1px solid #dedede;
      position: relative;
      top: -1px; /* minus the width of border */
      left: -1px; /* minus the width of border */

      #{$parent}.checked & {
        left: 16px !important;
      }
    }
  }
}