.modal-fullspace .modal-content {
  border: none;
  border-radius: 0;
  height: 100%;
  overflow-y: scroll;
}

.modal-fullspace .modal-dialog {
  max-width: none;
  margin: 0 auto;
  height: 100%;
}

.modal-fullspace {
  padding: 0 !important;
}