.navigation {
  border-bottom: 1px solid #eeeeee;
  padding: 0 1rem;
  min-height: 90px;
  
  & :global(.nav-link), & :global(.button) {
    display: flex;
    align-items: center;
    line-height: 22px;
    margin-top: 14px;
    margin-bottom: 14px;
    padding: 8px 15px !important;
    text-align: center;
    color: #444;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: Quicksand,sans-serif;
    -webkit-transition: margin .4s ease,padding .4s ease;
    -o-transition: margin .4s ease,padding,color .4s ease;
    transition: margin .4s ease,padding,color .4s ease;
    
    &:hover {
      color: #1abc9c !important;
    }
  }
  
  .logo {
    max-height: 4rem;
    min-width: 5em;
  }
  
  & :global(.dropdown-toggle) {
    background: transparent !important;
    
    &:hover, &:focus, &:active, &:focus-visible, &:focus-within, &:visited {
      background: rgba(31, 45, 65, 0.1) !important;
      box-shadow: none !important;
    }
  }
  
  & :global(.dropdown-menu) {
    width: 220px;
    background-color: #fff;
    box-shadow: 0 13px 42px 11px rgba(0,0,0, 0.05);
    border: 1px solid #eee;
    border-top: 2px solid #1abc9c;
    height: auto;
    z-index: 199;
    padding: 0;
    
    & :global(.dropdown-item) {
      font-size: 12px;
      font-weight: 700;
      padding-top: 10px;
      padding-bottom: 10px;
      text-transform: uppercase;
      transition: all linear 0.25s;
      
      &:global(.active) {
        background: #f8f9fa;
        color: #343a40;
      }
      
      &:hover {
        background: #f8f9fa;
        color: #1abc9c;
        padding-left: 2.4em;
      }
    }
  }

  & :global(.isLoggedIn) {
    color: #1abc9c !important;
  }
}