@import "variables";

.uppercase-link {
  text-transform: uppercase;
  font-size: .8em;
  letter-spacing: .05em;
}

.link-black-green {
  color: #555555;
}

.link-black-green:hover {
  color: $cpedit-primary-color;
}