.animated :global(.MuiBadge-badge):before,
.animated :global(.MuiBadge-badge):after {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  border-radius: 50%;
  box-shadow: 0 0 5px #1ABC9C;
  animation: glow-grow 3s ease-out infinite;
}

@keyframes glow-grow {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  80% {
    opacity: 0.7;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
