#content {
  font-size: 0.9rem;
}

.jdf-line-label {
  text-align: center;
  font-family: 'Roboto Mono', monospace;
}

.monospace {
  font-family: 'Roboto Mono', monospace !important;
}

.lh-1 {
  line-height: 1 !important;
}

#footer {
  font-size: 0.8em;
}

.input-group-xs>.custom-select, .input-group-xs>.form-control:not(textarea), 
.form-control.form-control-xs:not(textarea) {
  height: calc(.8em + .55rem + 2px);
}

.input-group-xs>.custom-select, .input-group-xs>.form-control, .input-group-xs>.input-group-append>.btn, .input-group-xs>.input-group-append>.input-group-text, .input-group-xs>.input-group-prepend>.btn, .input-group-xs>.input-group-prepend>.input-group-text, .form-control.form-control-xs, .btn-group-xs>.btn {
  padding: .25rem .35rem;
  font-size: .84rem;
  line-height: .8em;
}

.narrow-input {
  max-width: 3em;
}

.middle-narrow-input {
  max-width: 5em;
}

.display-child-on-hover:hover .display-on-parent-hover {
  visibility: visible;
}

.display-on-parent-hover {
  visibility: hidden;
}

.bg-hover-darken:hover {
  background: rgba(0,0,0,0.04);
  border-radius: .3em;
}

.popover {
  font-family: inherit !important;
}

.e-upload {
  border-radius: 0.3em;
}