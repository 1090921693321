@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

*:not(.e-icons){
  font-family: 'Quicksand', sans-serif !important;
}

// Fix for datepicker and timepicker in map
.e-popup-open {
  position: absolute !important;
}

// Customized Syncfusion
//@import "to-refactor/scss/ej2-bootstrap";

// Custom styles
//@import "to-refactor/scss/header";
@import "to-refactor/scss/buttons";
@import "to-refactor/scss/other";

// Components
@import "to-refactor/scss/components/connection-calendar";
@import "to-refactor/scss/components/connection-providers-editor";
@import "to-refactor/scss/components/inline-editor";
@import "to-refactor/scss/components/providers-viewer";
@import "to-refactor/scss/components/overlay-preloader";
@import "to-refactor/scss/components/modalbox";
@import "to-refactor/scss/components/notifications";
@import "to-refactor/scss/components/stop-select-autocomplete";
@import "to-refactor/scss/links";

// Icons
@import "to-refactor/scss/icons-tt";

// Adjustments for MUI components
@import "assets/css/mui-styles";

// Generally available styles
.font-size-80 {
  font-size: 0.8em !important;
}

.pb-3_5 {
  padding-bottom: 1.25rem !important;
}
