.notificationsStack {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  -moz-box-shadow: 0 0 64px 15px #ccc;
  -webkit-box-shadow: 0 0 64px 15px #ccc;
  box-shadow: 0 0 64px 15px #ccc;
  background: #cccccc80;
  
  @media (min-width: 768px) {
    max-width: 40vw;
  }

  @media (min-width: 992px) {
    max-width: 30vw;
  }

  @media (min-width: 1600px) {
    max-width: 20vw;
  }
}
