@import '../../../to-refactor/scss/variables';

.userInfoPrimary {
  text-transform: none;
  color: $cpedit-dropdown-link-color;
}

.userInfoSecondary {
  text-transform: none;
}
